import { Props } from "@/types";
import { MotorSpecsVehicleStore } from "./types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    clientId: {
      type: String,
      required: false,
      allowVariable: true,
      description: "Your client identifier on MotorCheck",
    } as Props.CGPropExtras<string>,
    clientSecret: {
      type: String,
      required: false,
      allowVariable: false,
      description: "Your client secret on MotorCheck",
    } as Props.CGPropExtras<string>,
    stagingEnvironment: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you are using the MotorCheck staging environment",
    } as Props.CGPropExtras<boolean>,
    returnMercedesRecallData: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the Mercedes recall data",
    } as Props.CGPropExtras<boolean>,

    returnSupplementaryDataFromUkvd: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the supplementary data from UKVD",
    } as Props.CGPropExtras<boolean>,

    returnSupplementaryVwDataFromEa: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the supplementary data from EA",
    } as Props.CGPropExtras<boolean>,

    returnDpfData: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the Diesel Particulate Filter (DPF) data",
    } as Props.CGPropExtras<boolean>,
    returnPriceEstimation: {
      type: Boolean,
      default: () => false,
      required: false,
      allowVariable: false,
      description: "Set this to true if you want to return the price estimation",
    } as Props.CGPropExtras<boolean>,
    condition: {
      type: Object,

      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,
    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: MotorSpecsVehicleStore; type: string }> => [
        { name: "euroStatusEnhanced", type: "string" },
        { name: "emEngineFamily", type: "string" },
        { name: "emCode", type: "number" },
        { name: "model", type: "string" },
        { name: "make", type: "string" },
        { name: "version", type: "string" },
        { name: "keeperChangeHistory", type: "array" },
        { name: "keeperStartDate", type: "date" },
        { name: "keeperOwnershipPeriodInMonths", type: "number" },
        { name: "wereKeeperDatesManuallyEntered", type: "boolean" },
        { name: "keeperEndDate", type: "date" },
        { name: "isCurrentKeeper", type: "boolean" },
        { name: "dateFirstRegistered", type: "date" },
        { name: "engineModelCode", type: "array" },
        { name: "engineLocation", type: "array" },
        // { name: "engineFamily", type: "array" },
        { name: "fuelType", type: "string" },
        { name: "vehicleRegistration", type: "string" },
        { name: "enteredRegistration", type: "string" },
        { name: "vin", type: "string" },
        { name: "engineNumber", type: "string" },

        { name: "numberOfDoors", type: "string" },
        // { name: "keeperChangeCount", type: "number" },
        { name: "euroStatus", type: "string" },
        { name: "yearOfManufacture", type: "number" },
        { name: "vinLast5", type: "number" },
        { name: "series", type: "string" },
        { name: "numberOfCylinders", type: "number" },
        { name: "engineCapacity", type: "number" },
        { name: "engineSize", type: "number" },
        { name: "maximumPowerInKw", type: "string" },
        { name: "bhp", type: "number" },
        { name: "torqueNm", type: "number" },
        { name: "colour", type: "string" },
        { name: "recalls", type: "array" },
        { name: "recallsCount", type: "number" },
        { name: "vehicleDataSource", type: "string" },
        { name: "mercedesEmissionsRecallMandatoryStatus", type: "string" },
        { name: "mercedesEmissionsRecallEligibleForExclusion", type: "boolean" },
        { name: "mercedesEmissionsRecallAccomplished", type: "boolean" },
        { name: "numberOfPreviousKeepers", type: "number" },
        { name: "dpfAffected", type: "boolean" },
        { name: "dpfConfidenceScoreReason", type: "array" },
        { name: "dpfEngineMake", type: "string" },
        { name: "dpfPlatform", type: "string" },
        { name: "estimatedPurchasePrice", type: "number" },
        { name: "estimatedPurchasePriceRoundedFiveHundred", type: "number" },
        { name: "estimatedCurrentPrice", type: "number" },
        { name: "depreciatedPurchaseValue", type: "number" },
        { name: "manufacturerPurchasePrice", type: "number" },
        { name: "vehicleEligible", type: "boolean" },
        { name: "vehicleRejectionTag", type: "string" },
        { name: "vehicleRejectionExplanation", type: "string" },
        { name: "ukvdEuroStatus", type: "string" },
        { name: "ukvdTypeApproval", type: "string" },
        { name: "mvrisTypeApprovalCategory", type: "string" },
        { name: "depreciatedCurrentValue", type: "number" },
        { name: "estimatedSalePrice", type: "number" },
        { name: "depreciatedSaleValue", type: "number" },
        { name: "depreciatedDuringOwnershipValue", type: "number" },
        { name: "manufactureDate", type: "date" },
        { name: "eaEngineConfig", type: "string" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: MotorSpecsVehicleStore; type: string }>>,

    collectOwnershipPeriod: {
      type: Boolean,
      required: false,
      default: false,
      description: "Collects the ownership period of the vehicle",
    } as Props.CGPropExtras<boolean>,

    allowManualKeeperEntry: {
      type: Boolean,
      required: false,
      default: false,
      description: "Allow's the claiamant to manually enter their keeper dates",
    } as Props.CGPropExtras<boolean>,
  };
}
