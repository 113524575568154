import {
  faFacebook,
  faFacebookSquare,
  faTwitter,
  faTwitterSquare,
  faWhatsapp,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDoubleRight,
  faAngleDown,
  faAngleRight,
  faArchive,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBirthdayCake,
  faBook,
  faBriefcase,
  faCalendar,
  faCarAlt,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCode,
  faCog,
  faLifeRing,
  faCopy,
  faCube,
  faDoorOpen,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileAlt,
  faFilePdf,
  faFilter,
  faHome,
  faIcons,
  faIdCard,
  faImage,
  faInfo,
  faInfoCircle,
  faLink,
  faList,
  faListUl,
  faPalette,
  faPen,
  faPercent,
  faPhone,
  faPlay,
  faPlus,
  faProjectDiagram,
  faQuestion,
  faQuestionCircle,
  faRandom,
  faRecycle,
  faRedo,
  faRedoAlt,
  faRulerVertical,
  faSave,
  faSearch,
  faSpinner,
  faTable,
  faTextHeight,
  faThList,
  faTimes,
  faTimesCircle,
  faTrash,
  faUpload,
  faUser,
  faUserCircle,
  faUserFriends,
  faVideo,
  faWarehouse,
  faSync,
  faArrowsRotate,
  faXmark,
  faExclamation,
  faBuilding,
  faCrown,
  faBullhorn,
  faRotateRight,
  faPaperPlane,
  faSpellCheck,
  faEnvelopeCircleCheck,
  faPersonChalkboard,
  faClipboard,
  faMessage,
  faFolderOpen,
  faAddressCard,
  faFingerprint,
  faReceipt,
  faCircleInfo,
  faBan,
  faCirclePause,
  faCirclePlay,
  faFileCircleCheck,
  faArrowUpRightFromSquare,
  faShare,
  faArrowUp,
  faArrowDown,
  faHashtag,
  faDroplet,
  faCloud,
  faSmog,
  faClock,
  faPause,
  faFlagCheckered,
  faDownload,
  faInbox,
  faEllipsis,
  faFileLines,
  faEllipsisVertical,
  faExpand,
  faFileSignature,
  faSignOutAlt,
  faLock,
  faPenToSquare,
  faGlobe,
  faEarthEurope,
  faFileContract,
  faBoxArchive,
  faHourglassEnd,
  faClipboardList,
  faMinus,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";

export const claimsGateIcons = [
  faPen,
  faArrowLeft,
  faAngleDown,
  faLifeRing,
  faFileImport,
  faExpand,
  faAngleRight,
  faTimes,
  faPlus,
  faArrowRight,
  faPlus,
  faChevronUp,
  faChevronDown,
  faArrowAltCircleDown,
  faExpandAlt,
  faEye,
  faCheck,
  faPercent,
  faEllipsisH,
  faSearch,
  faCheckCircle,
  faSave,
  faCopy,
  faBell,
  faEnvelope,
  faPlay,
  faTrash,
  faRedo,

  // FA FLOPPY DISK
  faExternalLinkAlt,
  faCode,
  faRandom,
  faCog,
  faInfo,
  faAngleDoubleRight,
  faArchive,
  faEye,
  faDoorOpen,
  faChartPie,
  faFile,
  faTable,
  faChevronLeft,
  faThList,
  faPalette,
  faUserFriends,
  faBook,
  faRedoAlt,
  faSpinner,
  faLink,
  faTextHeight,
  faProjectDiagram,
  faIcons,
  faImage,
  faFilter,
  faVideo,
  faRulerVertical,
  faListUl,
  faList,
  faCheckSquare,
  faUpload,
  faCalendar,
  faCarAlt,
  faCarSide,

  faCloud,

  faUser,
  faPhone,
  faHome,
  faBirthdayCake,
  faFilePdf,
  faIdCard,
  faCube,
  faUserCircle,
  faWarehouse,
  faBriefcase,
  faBars,
  faFileAlt,
  faFileAlt,
  faRecycle,
  faArrowAltCircleLeft,
  faSync,
  faArrowsRotate,
  faQuestion,
  faBuilding,
  faQuestionCircle,
  faInfoCircle,
  faTimesCircle,
  faExclamationTriangle,
  faXmark,
  faExclamation,
  faCrown,
  faBullhorn,
  faRotateRight,
  faPaperPlane,
  faSpellCheck,
  faSignOutAlt,
  faEnvelopeCircleCheck,
  faPersonChalkboard,
  faClipboard,
  faMessage,
  faFolderOpen,
  faAddressCard,
  faFingerprint,
  faSmog,
  faReceipt,
  faCircleInfo,
  faBan,
  faCirclePause,
  faCirclePlay,
  faFileCircleCheck,
  faArrowUpRightFromSquare,
  faFacebook,
  faWhatsapp,
  faTwitter,
  faShare,
  faFacebookSquare,
  faWhatsappSquare,
  faTwitterSquare,
  faArrowUp,
  faArrowDown,
  faHashtag,
  faDroplet,
  faClock,
  faPause,
  faFlagCheckered,
  faDownload,
  faInbox,
  faEllipsis,
  faFileLines,
  faEllipsisVertical,
  faFileSignature,
  faLock,
  faGlobe,
  faEarthEurope,
  faPenToSquare,
  faFileContract,
  faBoxArchive,
  faHourglassEnd,
  faClipboardList,
  faMinus,
];

import {
  faSailboat,
  faPlane,
  faCarSide,
  faCaravanSimple,
  faMotorcycle,
  faCaravan,
  faVanShuttle,
  faTrailer,
  faTruckField,
  faBicycle,
  faMoped,
} from "@fortawesome/pro-regular-svg-icons";

export const sgIconsObj = {
  faSailboat,
  faPlane,
  faCarSide,
  faCaravanSimple,
  faMotorcycle,
  faCaravan,
  faVanShuttle,
  faTrailer,
  faTruckField,
  faBicycle,
  faMoped,
};

export const sgIcons = Object.values(sgIconsObj);
