<script lang="ts">
import Vue, { PropType } from "vue";
import HeadingContent from "@/components/shared/styleWrappers/headingContent/headingContent.vue";

import { GreyTextColour } from "../types";

export default Vue.extend({
  name: "CardHeader",
  components: {
    HeadingContent,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: true,
    },

    hideSupportingText: {
      type: Boolean,
      default: false,
    },

    supportingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-700" as GreyTextColour,
    },
    supportingBadge: {
      type: String,
      required: false,
    },
    supportingBadgeClass: {
      type: String,
      default: "bg-primary-50 text-primary-700",
    },
    headingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-900" as GreyTextColour,
    },

    headingMarginBottom: {
      required: false,
      type: String,
      default: "mb-1",
    },
  },
});
</script>

<template>
  <HeadingContent
    :heading="heading"
    :supportingText="supportingText"
    :headingTextClass="headingTextClass"
    :supportingTextClass="supportingTextClass"
    :supportingBadge="supportingBadge"
    :supportingBadgeClass="supportingBadgeClass"
    :hideSupportingText="hideSupportingText"
    :headingTextSize="'text-lg'"
    :supportingTextSize="'text-sm'"
    :headingMarginBottom="headingMarginBottom"
  >
    <slot name="extraHeadingContent" slot="extraHeadingContent"></slot>
    <slot name="extraSupportingContent" slot="extraSupportingContent"></slot>
    <slot name="right" slot="right"></slot>
    <slot></slot>
  </HeadingContent>
</template>
