<script lang="ts">
import Vue from "vue";
import { getProps, getData, mappedComputed, BlockConsentInstance } from "./BlockConsent";
import { methods } from "./methods";

import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import { computed } from "./computed";
export default Vue.extend({
  name: "BlockConsent",
  components: {
    //BlockParagraph,
    BlockCheckbox,
  },
  data() {
    return { ...getData(this) };
  },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    cgPrivacyPolicyLinkText(): string {
      return computed.cgPrivacyPolicyLinkText(this as BlockConsentInstance);
    },
    workspaceTermsAndConditionsLinkParsed(): string {
      return computed.workspaceTermsAndConditionsLinkParsed(this as BlockConsentInstance);
    },

    workspaceId(): string {
      return this.$store.getters["permissions/getWorkspaceId"];
    },

    workspaceAllowCustomPolicy(): boolean {
      return this.$store.getters["navigation/getWorkspaceAllowCustomPolicy"];
    },
  },
  async mounted() {
    await methods.mounted(this as BlockConsentInstance);
  },
  methods: {
    async validations() {
      return methods.validations(this as BlockConsentInstance);
    },
    async computes() {
      return methods.computes(this as BlockConsentInstance);
    },
  },
  watch: {
    workspacePrivacyPolicyLink() {
      // Just in case the user adds a policy link, need to make sure the render updates with correct wording
      if (this.workspacePrivacyPolicyLink.length > 0) {
        this.checkboxes.cgPrivacy.linkText = "Claims Gate Privacy Policy";
      } else {
        this.checkboxes.cgPrivacy.linkText = "Privacy Policy";
      }
      console.log("run watcher", this.checkboxes.cgPrivacy.linkText);
    },
  },
});
</script>
<template>
  <div>
    <BlockCheckbox
      v-bind="checkboxes.cgPrivacy"
      v-bind.sync="checkboxes.cgPrivacy"
      :linkText="cgPrivacyPolicyLinkText"
      :linkTarget="
        workspaceAllowCustomPolicy ? `${privacyPolicyLink}?queryWorkspaceId=${workspaceId}` : privacyPolicyLink
      "
      :padding="padding"
      :text="privacyPolicyText"
    />
    <template v-if="workspacePrivacyPolicyLink.length > 0">
      <BlockCheckbox
        v-bind="checkboxes.workspacePrivacy"
        v-bind.sync="checkboxes.workspacePrivacy"
        :linkText="navigationWorkspaceName + ' Privacy Policy'"
        :linkTarget="workspacePrivacyPolicyLink"
        :padding="padding"
      />
    </template>
    <template v-if="smsCheckBox">
      <BlockCheckbox v-bind="checkboxes.sms" v-bind.sync="checkboxes.sms" :padding="padding" />
    </template>
  </div>
</template>
