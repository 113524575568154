import {
  VerificationLog,
  T2AVerificationSession,
  YotiVerificationSession,
  YotiCheckResult,
} from "@claimsgate/core-types";
import { DateTime } from "luxon";
import { appendUnique } from "../ClaimsGate/utils/AppendUnique";
import { DateService } from "../ClaimsGate/DateService";
import { WorkspaceService } from "@claimsgate/core";

/** Parses the verification logs and includes computed fields as a part of the exported spreadsheet **/
export function appendComputedVerificationLogs(
  claim: any,
  appendedExportFields: any,
  fieldsForExportKeys: string[]
): void {
  const verificationLogs = claim.verification_logs as Array<VerificationLog>;
  console.log("<<< Before verificationLogs", verificationLogs);

  // Find the most recent T2A Verification Log by date, converting the stringified version of a date using DateTime
  appendElectoralRoll(verificationLogs, appendedExportFields, claim);
  appendYotiVerification(verificationLogs, appendedExportFields, claim, fieldsForExportKeys);
  appendManualVerification(verificationLogs, appendedExportFields, claim);
  appendAML(verificationLogs, appendedExportFields, claim, fieldsForExportKeys);

  // Remove the key for verification logs from the list of fields to export
  delete appendedExportFields.verification_logs;
}

/** Appends the computed fields for the latest AML verification */
function appendAML(
  verificationLogs: VerificationLog[],
  appendedExportFields: any,
  claim: any,
  fieldsForExportKeys: string[]
) {
  const yotiAMLVerificationLogs = verificationLogs.filter(
    (verificationLog) => verificationLog.method === "Yoti" && verificationLog.types.includes("Anti Money Laundering")
  );

  if (yotiAMLVerificationLogs.length === 0) {
    appendUnique(appendedExportFields, "verifiedAML");
    claim.verifiedAML = "";
    appendUnique(appendedExportFields, "attemptedAML");
    claim.attemptedAML = "No";
    return;
  }

  const hasPassedAMLVerification = yotiAMLVerificationLogs.some(
    (verificationLog: any) => verificationLog?.result?.amlResult?.recommendation?.value === "APPROVE"
  );

  appendUnique(appendedExportFields, "verifiedAML");
  claim.verifiedAML = hasPassedAMLVerification ? "APPROVE" : "REJECT";
  appendUnique(appendedExportFields, "attemptedAML");
  claim.attemptedAML = "Yes";

  let mostRecentYotiAMLVerificationLogs = yotiAMLVerificationLogs.sort((a, b) => {
    const aDate = DateTime.fromISO(a.date as unknown as string);
    const bDate = DateTime.fromISO(b.date as unknown as string);
    return bDate.valueOf() - aDate.valueOf();
  });

  // filter after sort still retains sort order
  const mostRecentPasses = mostRecentYotiAMLVerificationLogs.filter(
    (verificationLog: any) => verificationLog?.result?.amlResult?.recommendation?.value === "APPROVE"
  );
  if (mostRecentPasses.length > 0) {
    mostRecentYotiAMLVerificationLogs = mostRecentPasses;
  }

  const mostRecentYotiVerificationLog = mostRecentYotiAMLVerificationLogs[0];

  if (isYotiVerificationLog(mostRecentYotiVerificationLog)) {
    console.log("<<< mostRecentYotiVerificationLog", mostRecentYotiVerificationLog);

    appendUnique(appendedExportFields, "amlTotalHits");
    claim.amlTotalHits = mostRecentYotiVerificationLog.result?.amlResult?.totalHits;

    appendUnique(appendedExportFields, "amlAssociatedCountryCodes");
    claim.amlAssociatedCountryCodes = mostRecentYotiVerificationLog.result?.amlResult?.associatedCountryCodes;

    appendUnique(appendedExportFields, "amlCategories");
    claim.amlCategories = mostRecentYotiVerificationLog.result?.amlResult?.categories;
  }
}

/** Appends the computed fields for the latest electoral roll verification */
function appendElectoralRoll(verificationLogs: VerificationLog[], appendedExportFields: any, claim: any) {
  const t2aVerificationLogs = verificationLogs.filter((verificationLog) => verificationLog.method === "T2A");

  if (t2aVerificationLogs.length === 0) {
    appendUnique(appendedExportFields, "verifiedElectoralRoll");
    claim.verifiedElectoralRoll = "";
    appendUnique(appendedExportFields, "attemptedElectoralRoll");
    claim.attemptedElectoralRoll = "No";
    return;
  }

  const hasPassedT2AVerification = t2aVerificationLogs.some((verificationLog) => verificationLog.passed);

  appendUnique(appendedExportFields, "verifiedElectoralRoll");
  claim.verifiedElectoralRoll = hasPassedT2AVerification ? "APPROVE" : "REJECT";
  appendUnique(appendedExportFields, "attemptedElectoralRoll");
  claim.attemptedElectoralRoll = "Yes";

  const mostRecentT2AVerificationLog = t2aVerificationLogs.sort((a, b) => {
    const aDate = DateTime.fromISO(a.date as unknown as string);
    const bDate = DateTime.fromISO(b.date as unknown as string);
    return bDate.valueOf() - aDate.valueOf();
  })[0];

  if (isT2AVerificationLog(mostRecentT2AVerificationLog)) {
    // Append each of the computed fields to the list of export fields
    appendUnique(appendedExportFields, "electoralRollMatchCodeAddress");
    appendUnique(appendedExportFields, "electoralRollMatchCodeForename");
    appendUnique(appendedExportFields, "electoralRollMatchCodeSurname");
    appendUnique(appendedExportFields, "electoralRollValidationStatus");
    appendUnique(appendedExportFields, "electoralRollVerificationResult");

    // Append the computed fields to the claim
    claim.electoralRollMatchCodeAddress = mostRecentT2AVerificationLog.result.electoralRollMatchCodeAddress;
    claim.electoralRollMatchCodeForename = mostRecentT2AVerificationLog.result.electoralRollMatchCodeForename;
    claim.electoralRollMatchCodeSurname = mostRecentT2AVerificationLog.result.electoralRollMatchCodeSurname;
    claim.electoralRollValidationStatus = mostRecentT2AVerificationLog.result.electoralRollValidationStatus;

    claim.electoralRollVerificationResult = mostRecentT2AVerificationLog.passed ? "Passed" : "Failed";
  }
}

/** Type guard to check if a given verification log is a T2A verification log */
function isT2AVerificationLog(verificationLog: VerificationLog): verificationLog is VerificationLog & {
  result: T2AVerificationSession;
} {
  return (
    typeof verificationLog.result === "object" &&
    !Array.isArray(verificationLog) &&
    (verificationLog.result as T2AVerificationSession).electoralRollMatchCodeAddress !== undefined
  );
}

/** Type guard to check if a given verification log is a Yoti verification log */
function isYotiVerificationLog(verificationLog: VerificationLog): verificationLog is VerificationLog & {
  result: YotiVerificationSession;
} {
  return (
    typeof verificationLog?.result === "object" &&
    !Array.isArray(verificationLog) &&
    (verificationLog.result as YotiVerificationSession).sessionId !== undefined
  );
}

/** Appends the computed fields for the latest Yoti verification */
function appendYotiVerification(
  verificationLogs: VerificationLog[],
  appendedExportFields: any,
  claim: any,
  fieldsForExportKeys: string[]
) {
  const yotiVerificationLogs = verificationLogs.filter((verificationLog) => verificationLog.method === "Yoti");
  if (yotiVerificationLogs.length === 0) {
    appendUnique(appendedExportFields, "attemptedIdentityUpload");
    claim.attemptedIdentityUpload = "No";
    appendUnique(appendedExportFields, "verifiedIdentityUpload");
    claim.verifiedIdentityUpload = "";
    return;
  }

  const hasPassedYotiVerification = yotiVerificationLogs.some((verificationLog) => verificationLog.passed);
  appendUnique(appendedExportFields, "attemptedIdentityUpload");
  claim.attemptedIdentityUpload = "Yes";
  appendUnique(appendedExportFields, "verifiedIdentityUpload");
  claim.verifiedIdentityUpload = hasPassedYotiVerification ? "APPROVED" : "REJECTED";

  let mostRecentYotiVerificationLogs = yotiVerificationLogs.sort((a, b) => {
    const aDate = DateTime.fromISO(a.date as unknown as string);
    const bDate = DateTime.fromISO(b.date as unknown as string);
    return bDate.valueOf() - aDate.valueOf();
  });

  const mostRecentPasses = mostRecentYotiVerificationLogs.filter((verificationLog) => verificationLog.passed);
  if (mostRecentPasses.length > 0) {
    mostRecentYotiVerificationLogs = mostRecentPasses;
  } else {
    const mostRecentIdUploads = mostRecentYotiVerificationLogs.filter(
      (verificationLog: any) => !!verificationLog.result?.idDocuments?.length
    );
    mostRecentYotiVerificationLogs = mostRecentIdUploads;
  }

  const mostRecentYotiVerificationLog = mostRecentYotiVerificationLogs[0];

  if (isYotiVerificationLog(mostRecentYotiVerificationLog)) {
    // For each of the computed fields, append the value to the claim
    if (fieldsForExportKeys.includes("identityPictures")) {
      mostRecentYotiVerificationLog.result.idDocuments.forEach((idDocument, index) => {
        claim[`identityPicture${index + 1}.url`] = `${window.location.origin}/files/${idDocument.id}`;
        appendUnique(appendedExportFields, `identityPicture${index + 1}.url`);
      });
    }
    if (fieldsForExportKeys.includes("selfiePictures")) {
      if (mostRecentYotiVerificationLog.result?.livenessCheck?.media?.length > 0) {
        mostRecentYotiVerificationLog.result.livenessCheck.media.forEach((faceMatchCheck, index) => {
          claim[`selfiePicture${index + 1}.url`] = `${window.location.origin}/files/${faceMatchCheck.fileId}`;
          appendUnique(appendedExportFields, `selfiePicture${index + 1}.url`);
        });
      }
    }

    // Append additional fields
    // TODO: Require "identityUploadExtractedFields" to be a part of fieldsForExportKeys
    if (
      fieldsForExportKeys.includes("identityUploadExtractedFields") &&
      mostRecentYotiVerificationLog.result?.extractedFields
    ) {
      mostRecentYotiVerificationLog.result.extractedFields.forEach((field) => {
        Object.entries(field).forEach(([key, value]) => {
          if (typeof value !== "object") {
            const pascalKey = key
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join("");
            const fieldKey = `identityUploadExtracted${pascalKey}`;
            appendUnique(appendedExportFields, fieldKey);
            claim[fieldKey] = value;
          }
        });
      });
    }

    appendUnique(appendedExportFields, "identityUploadDocumentAutenticityCheck");

    console.log("<<< mostRecentYotiVerificationLog", mostRecentYotiVerificationLog);

    claim.identityUploadDocumentAutenticityCheck = mostRecentYotiVerificationLog.result?.authenticityChecks
      ? parseCheckResult(mostRecentYotiVerificationLog.result.authenticityChecks)
      : "";

    appendUnique(appendedExportFields, "identityUploadFaceMatchCheck");
    claim.identityUploadFaceMatchCheck = mostRecentYotiVerificationLog.result?.faceMatchCheck
      ? parseCheckResult(mostRecentYotiVerificationLog.result.faceMatchCheck)
      : "";

    appendUnique(appendedExportFields, "identityUploadLivenessCheck");
    claim.identityUploadLivenessCheck = mostRecentYotiVerificationLog.result?.livenessCheck
      ? parseCheckResult(mostRecentYotiVerificationLog.result.livenessCheck)
      : "";
  }
}

function appendManualVerification(verificationLogs: VerificationLog[], appendedExportFields: any, claim: any) {
  const manualVerificationLogs = verificationLogs.filter(
    (verificationLog) => verificationLog.method === "Manual Upload"
  );
  if (manualVerificationLogs.length === 0) {
    appendUnique(appendedExportFields, "attemptedManualIdentityUpload");
    claim.attemptedManualIdentityUpload = "No";
    appendUnique(appendedExportFields, "verifiedManualIdentityUpload");
    claim.verifiedManualIdentityUpload = "";
    return;
  }

  appendUnique(appendedExportFields, "attemptedManualIdentityUpload");
  claim.attemptedManualIdentityUpload = "Yes";
  const hasPassedManualVerification = manualVerificationLogs.some((verificationLog) => verificationLog.passed);

  appendUnique(appendedExportFields, "verifiedManualIdentityUpload");
  claim.verifiedManualIdentityUpload = hasPassedManualVerification ? "APPROVED" : "AWAITING REVIEW";
}

function parseCheckResult(match: YotiCheckResult | Array<YotiCheckResult>): string {
  if (!match) {
    return "";
  }
  function parseMatch(match: YotiCheckResult) {
    if (match?.value === undefined) return "";
    return match.value === "APPROVE" ? "Approve" : match.value === "REJECT" ? "Reject" : "";
  }

  if (Array.isArray(match)) {
    return match.map((match) => parseMatch(match)).join(", ");
  } else {
    return parseMatch(match);
  }
}

/** Gets the workspace name from workspaceId and caches it for reuse */
const workspaceNames = {};
async function getWorkspaceName(firebase, workspaceId) {
  if (workspaceNames[workspaceId]) {
    return workspaceNames[workspaceId];
  } else {
    const workspaceService = new WorkspaceService(firebase);
    const workspace = await workspaceService.getWorkspace(workspaceId);
    if (workspace?.data?.name) {
      workspaceNames[workspaceId] = workspace.data.name;
      return workspace.data.name;
    } else {
      workspaceNames[workspaceId] = workspaceId.toString();
      return workspaceId;
    }
  }
}

/** Converts the given amount from the given currency to GBP */
export async function convertToGBP(amount, fromCurrency, rates) {
  let amountInGBP: number;
  if (!rates[fromCurrency]) {
    amountInGBP = 0;
  } else {
    // Convert the amount to GBP
    amountInGBP = Math.floor(amount / rates[fromCurrency]);
  }

  return { amountInGBP, exchangeRate: rates[fromCurrency] };
}

function stripHTML(htmlString) {
  return htmlString
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/<br>/g, "\n")
    .replace(/<a href="([^"]+)">[^<]+<\/a>/g, "$1")
    .replace(/&nbsp;/g, "  ");
}

export type PreprocessClaimForExportMiscData = {
  funnelId: string;
  variables: any[];
  currencyConversionRates?: any;
};
export async function preprocessClaimForExport(
  firebase,
  claim,
  fieldsForExportKeys,
  appendedFields,
  redactedFields,
  miscData: PreprocessClaimForExportMiscData
) {
  if (
    !claim.verification_logs &&
    fieldsForExportKeys.some((key) =>
      ["verifiedElectoralRoll", "attemptedElectoralRoll", "verifiedIdentityUpload", "attemptedIdentityUpload"].includes(
        key
      )
    )
  ) {
    appendUnique(appendedFields, "verifiedElectoralRoll");
    appendUnique(appendedFields, "attemptedElectoralRoll");
    claim.verifiedElectoralRoll = "";
    claim.attemptedElectoralRoll = "No";
    appendUnique(appendedFields, "verifiedIdentityUpload");
    appendUnique(appendedFields, "attemptedIdentityUpload");
    claim.verifiedIdentityUpload = "";
    claim.attemptedIdentityUpload = "No";
  }

  for (const key in claim) {
    // artificially add fields for each message
    // if (key == "messages") {
    //   for (let message of claim[key]) {
    //     if (message.fileIds) {
    //       for (let subkey in message) {
    //         //["date", "isReminder"].forEach((subkey) => {
    //         let subvalue = message[subkey];
    //         if (subvalue) {
    //           let newKey = `Mail - ${message.subject} - ${subkey}`;
    //           // add the value to be included in the CSV
    //           appendUnique(this.appendedFields, newKey);
    //           // denormalize the value
    //           claim[newKey] = subvalue;
    //         }
    //         //});
    //       }
    //     }
    //   }
    // }

    // handling for files/agreements
    if (claim[key]?.type == "agreement" || claim[key]?.type == "file") {
      if (!fieldsForExportKeys.includes(key)) continue;

      let ids = [];
      if (claim[key]?.type == "agreement") {
        ids.push(claim[key]?.file?.id);
      } else if (claim[key]?.type == "file") {
        ids.push(claim[key]?.id);
      } else if (claim[key]?.type == "array of files") {
        ids = claim[key]?.files?.map((file) => file.id) || [];
      } else {
        // ! Might want to add a sentry log here for agreement/file id not found.
      }
      ids.forEach((id, index) => {
        if (id) {
          ["dateAgreed", "status", "name"].forEach((subkey) => {
            let subvalue = claim[key]?.[subkey];
            if (subvalue) {
              if (subkey == "dateAgreed") {
                const dateService = new DateService();
                subvalue = dateService.toStringWithTime(subvalue);
              }

              // add the value to be included in the CSV
              const fieldKey = ids.length > 1 ? `${key}.${subkey}${index}` : `${key}.${subkey}`;
              appendUnique(appendedFields, fieldKey);
              // denormalize the value
              claim[fieldKey] = subvalue;
            }
          });
          // remove the original field from being included in the CSV
          appendUnique(redactedFields, key);
          // handle the URL field
          const urlKey = ids.length > 1 ? `${key}.url${index}` : `${key}.url`;
          appendUnique(appendedFields, urlKey);
          claim[urlKey] = window.location.origin + "/files/" + id;
          const idKey = ids.length > 1 ? `${key}.id${index}` : `${key}.id`;
          appendUnique(appendedFields, idKey);
          claim[idKey] = id;
        }
      });
    }

    // handling T types
    if (miscData.variables.find((variable) => variable.field === key)?.type == "T") {
      if (typeof claim[key] == "object" && Object.keys(claim[key]).length > 0) {
        if (!fieldsForExportKeys.includes(key)) continue;
        for (const subkey in claim[key]) {
          const subvalue = claim[key]?.[subkey];
          if (subvalue) {
            // add the value to be included in the CSV
            appendUnique(appendedFields, `${key}.${subkey}`);
            // denormalize the value
            claim[`${key}.${subkey}`] = subvalue;
          }
        }
      }
      // remove the original field from being included in the CSV
      appendUnique(redactedFields, key);
    }

    // handling T types and Array<T> types
    const variableType = miscData.variables.find((variable) => variable.field === key)?.type;
    if (variableType == "Array<T>") {
      if (Array.isArray(claim[key])) {
        claim[key].forEach((item, index) => {
          if (typeof item == "object" && Object.keys(item).length > 0) {
            for (const subkey in item) {
              const subvalue = item[subkey];
              if (subvalue) {
                // add the value to be included in the CSV
                appendUnique(appendedFields, `${key}.${index}.${subkey}`);
                // denormalize the value
                claim[`${key}.${index}.${subkey}`] = subvalue;
              }
            }
          }
        });
      }

      // Remove the original field from being included in the CSV
      appendUnique(redactedFields, key);
    }

    // Append the computed fields for the referral
    if (key == "referrerId" && fieldsForExportKeys.includes("referralWorkspaceName")) {
      // Note: we only need this since this does not exist as a real variable as far as users.vue:startExportClicked is concerned
      appendUnique(appendedFields, "referralWorkspaceName");
      claim["referralWorkspaceName"] = await getWorkspaceName(firebase, claim[key]);
    }

    // Append the computed fields for the verification logs
    if (
      key === "verification_logs" &&
      fieldsForExportKeys.some((key) =>
        [
          "verifiedElectoralRoll",
          "attemptedElectoralRoll",
          "verifiedIdentityUpload",
          "attemptedIdentityUpload",
          "electoralRollMatchCodeAddress",
          "electoralRollMatchCodeForename",
          "electoralRollMatchCodeSurname",
          "electoralRollValidationStatus",
          "electoralRollVerificationResult",
          "identityPictures",
          "selfiePictures",
          "identityUploadExtractedFields",
          "identityUploadDocumentAutenticityCheck",
          "identityUploadFaceMatchCheck",
          "identityUploadLivenessCheck",
          "identityUploadAMLCheck",
        ].includes(key)
      )
    ) {
      appendComputedVerificationLogs(claim, appendedFields, fieldsForExportKeys);
    }

    // OneCoin valuation computations
    if (
      miscData.funnelId === "lTVfyMTtO7v0Gmgdo7Dg" &&
      key === "investmentAmountInOneCoin" &&
      fieldsForExportKeys.includes("investmentAmountInOneCoin") &&
      claim[key].amount != undefined &&
      claim[key].currencyCode != undefined
    ) {
      const conversionData = await convertToGBP(
        claim[key].amount,
        claim[key].currencyCode,
        miscData.currencyConversionRates
      );
      appendUnique(appendedFields, `investmentAmountInOneCoin.exchangeRate`);
      claim[`investmentAmountInOneCoin.exchangeRate`] = conversionData.exchangeRate;
      appendUnique(appendedFields, `investmentAmountInOneCoin.amountInGBP`);
      claim[`investmentAmountInOneCoin.amountInGBP`] = conversionData.amountInGBP;
    }

    if (key === "notes" && Array.isArray(claim[key]) && claim[key].length > 0) {
      appendUnique(appendedFields, `notes`);
      let notes = "";
      for (const note of claim[key]) {
        console.log("export note", note);
        const userName = note.workspaceUserName;
        const text = note.text;
        const date = note.createdAt;
        notes += `${userName} - ${date} - ${stripHTML(text)}\n`;
      }
      claim["notes"] = notes;
    }
  }
}
