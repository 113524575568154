import { getFirebaseBackend } from "../../authUtils.js";
import { getUserHelper } from "../../helpers/ClaimsGate/UserHelper";

// Services
import { WorkspaceService } from "@claimsgate/core";
import { getUser } from "@claimsgate/core";
import { tryParseJSON } from "./auth.js";
export const state = {
  workspaceId: tryParseJSON(localStorage.getItem("workspaceId")) ?? "",
  workspace: tryParseJSON(localStorage.getItem("workspace")) ?? {},

  userDocument: {},
  userWorkspaces: [],
  userWorkspacePermissions: [],
  userWorkspaceMembers: [],
  userApps: tryParseJSON(localStorage.getItem("userApps")) ?? [],

  claim: tryParseJSON(localStorage.getItem("claim")),

  // workspaceCollaborators: [],
  workspaceExternalCollaborators: [],

  // these Vue X variable store which workspace is selected in respective apps
  workspaceSelectedWorkspace: JSON.parse(localStorage.getItem("workspaceSelectedworkspace")) ?? "",
  homeSelectedWorkspace: JSON.parse(localStorage.getItem("homeSelectedWorkspace")) ?? "",
};

export const getters = {
  getUserDocument(state) {
    return state.userDocument;
  },

  getUserWorkspaces(state) {
    return state.userWorkspaces;
  },
  getUserWorkspacePermissions(state) {
    return state.userWorkspacePermissions;
  },
  getUserWorkspaceMembers(state) {
    return state.userWorkspaceMembers;
  },
  getWorkspaceId(state) {
    return state.workspaceId;
  },
  getWorkspaceSelectedWorkspace(state) {
    return state.workspaceSelectedWorkspace;
  },
  getHomeSelectedWorkspace(state) {
    return state.homeSelectedWorkspace;
  },
  getWorkspace(state) {
    return state.workspace;
  },
  getClaim(state) {
    return state.claim;
  },
  // getWorkspaceCollaborators(state) {
  //   return state.workspaceCollaborators;
  // },
  getWorkspaceExternalCollaborators(state) {
    return state.workspaceExternalCollaborators;
  },
  getUserApps(state) {
    return state.userApps;
  },
};

export const mutations = {
  setUserDocument(state, userDocument) {
    state.userDocument = userDocument;
  },
  setUserWorkspaces(state, workspaces) {
    state.userWorkspaces = workspaces;
  },
  setUserWorkspacePermissions(state, workspacePermissions) {
    state.userWorkspacePermissions = workspacePermissions;
  },
  setWorkspaceId(state, workspaceId) {
    // for reactivity purposes
    state.workspaceId = workspaceId;
    saveState("workspaceId", workspaceId);
  },
  setUserWorkspaceMembers(state, workspaceMembers) {
    state.userWorkspaceMembers = workspaceMembers;
  },
  setWorkspaceSelectedWorkspace(state, workspaceSelectedWorkspace) {
    // for reactivity purposes
    state.workspaceSelectedWorkspace = workspaceSelectedWorkspace;
    saveState("workspaceSelectedWorkspace", workspaceSelectedWorkspace);
  },
  setHomeSelectedWorkspace(state, homeSelectedWorkspace) {
    // for reactivity purposes
    state.homeSelectedWorkspace = homeSelectedWorkspace;
    saveState("homeSelectedWorkspace", homeSelectedWorkspace);
  },
  setWorkspace(state, workspace) {
    // for reactivity purposes
    state.workspace = workspace;
    saveState("workspace", workspace);
  },
  setClaim(state, claim) {
    // for reactivity purposes
    state.claim = claim;
    saveState("claim", claim);
  },
  // setWorkspaceCollaborators(state, workspaceCollaborators) {
  //   state.workspaceCollaborators = workspaceCollaborators;
  // },
  // ! should be deprecated
  setWorkspaceExternalCollaborators(state, workspaceExternalCollaborators) {
    state.workspaceExternalCollaborators = workspaceExternalCollaborators;
  },
  setUserApps(state, userApps) {
    // for reactivity purposes
    state.userApps = userApps;
    saveState("userApps", userApps);
  },

  setWorkspacePrimarySubdomain(state, primarySubdomain) {
    state.workspace.primarySubdomain = primarySubdomain;
    saveState("workspace", state.workspace);
  },
};

export const actions = {
  /**Set Vue X version of user document */
  setUserDocument({ commit }, userDocument) {
    commit("setUserDocument", userDocument);
  },

  /** Fetch latest user document from firebase */
  async fetchUserDocument({ commit }) {
    const userHelper = getUserHelper();
    const userId = userHelper.getUserId();
    const [userDocument] = await getUser(getFirebaseBackend().firestore(), userId);
    if (userDocument) {
      commit("setUserDocument", userDocument);
    }
  },

  setUserWorkspaces({ commit }, workspaces) {
    commit("setUserWorkspaces", workspaces);
  },

  getWorkspaceIdVueX({ state }) {
    return state.workspaceId;
  },

  getWorkspaceVueX({ state }) {
    return state.workspace;
  },

  async setWorkspaceId({ commit }, workspaceId) {
    commit("setWorkspaceId", workspaceId);
    // workspaceId can be blank string under some conditions
    if (workspaceId && workspaceId.length > 0) {
      // update user specific permissions in the selected workspace
      const userHelper = getUserHelper();
      const userId = userHelper.getUserId();
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
      const { data: userWorkspacePermissions } = await workspaceService.getUserPermissions(workspaceId, userId);
      commit("setUserWorkspacePermissions", userWorkspacePermissions);

      const getWorkspaceMembers = getFirebaseBackend().firebaseFunctions().httpsCallable("getMembers");
      const { data: workspaceMembers } = await getWorkspaceMembers({ workspaceId });

      if (workspaceMembers?.members && workspaceMembers?.workspaceId === workspaceId) {
        commit("setUserWorkspaceMembers", workspaceMembers.members ?? []);
      }
    }
  },

  async setWorkspace({ commit }, workspaceId) {
    let workspace;
    let keyQuerySnapshot;
    if (workspaceId) {
      // Fetch workspaceData
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());

      ({ data: workspace } = await workspaceService.getWorkspace(workspaceId));

      if (!workspace) {
        workspace = {};
      }

      // Fetch/Create API Key
      var createApiKey = getFirebaseBackend().firebaseFunctions().httpsCallable("createApiKey");

      const keyCollectionRef = getFirebaseBackend().firestore().collection("keys");

      var keyQueryRef = keyCollectionRef.where("workspaceId", "==", workspaceId);
      try {
        keyQuerySnapshot = await keyQueryRef.get();
      } catch (e) {
        // user doesn't have permissions to view the API key
      }

      if (keyQuerySnapshot && keyQuerySnapshot.empty) {
        await createApiKey({
          workspaceId: workspaceId,
        });
        try {
          keyQuerySnapshot = await keyQueryRef.get();
        } catch (e) {
          // user doesn't have permissions to view the API key
        }
      }
    }

    const workspaceData = {
      name: workspace?.name || null,
      logo: workspace?.logo || null,
      footer: workspace?.footer || null,
      intercomAppId: workspace?.intercomAppId || null,
      subdomain: workspace?.subdomain || null,
      companyName: workspace?.companyName || null,
      companyAddressLine1: workspace?.companyAddressLine1 || null,
      companyCity: workspace?.companyCity || null,
      companyPostcode: workspace?.companyPostcode || null,
      companyAddress: workspace?.companyAddress || null,
      privacyPolicyAddress: workspace?.privacyPolicyAddress || null,
      ownerId: workspace?.ownerId ?? null,
      themeSelectedId: workspace?.themeSelectedId ?? null,
      primarySubdomain: workspace?.primarySubdomain ?? null,
      displaySRATag: workspace?.displaySRATag ?? null,
      allowNameChanges: workspace?.allowNameChanges ?? true,
      allowAddressChanges: workspace?.allowAddressChanges ?? true,
      allowContactDetailChanges: workspace?.allowContactDetailChanges ?? true,
      allowClaimDeletion: workspace?.allowClaimDeletion ?? true,
      allowAccountDeletion: workspace?.allowAccountDeletion ?? true,
      allowCustomPolicy: workspace?.allowCustomPolicy ?? false,
      apiKey: null,
      senderId: workspace?.senderId || null,
    };

    if (workspaceId && keyQuerySnapshot && !keyQuerySnapshot?.empty) {
      workspaceData.apiKey = keyQuerySnapshot.docs[0].ref.id;
    }

    // update workspaceData in state
    commit("setWorkspace", workspaceData);
  },

  setWorkspacePrimarySubdomain({ commit }, primarySubdomain) {
    commit("setWorkspacePrimarySubdomain", primarySubdomain);
  },

  async setWorkspaceTheme({ state, commit }, workspaceId) {
    let workspace;
    if (workspaceId) {
      // Fetch workspaceData
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());

      ({ data: workspace } = await workspaceService.getWorkspace(workspaceId));

      if (!workspace) {
        workspace = {};
      }
    }

    const workspaceData = {
      ...state.workspace,
      themeSelectedId: workspace?.themeSelectedId ?? null,
    };

    // update workspaceData in state
    commit("setWorkspace", workspaceData);
  },

  async setUserApps({ commit }, { workspaceId, userId }) {
    if (!workspaceId || !userId) {
      commit("setUserApps", []);
    }

    const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
    const { data: userPermissions } = await workspaceService.getUserPermissions(workspaceId, userId);

    if (userPermissions && userPermissions.length > 0) {
      const uniqueUserApps = findUniqueUserApps(userPermissions);
      commit("setUserApps", uniqueUserApps);
    } else {
      commit("setUserApps", []);
    }
  },

  // Clears workspace data from localStorage
  clearWorkspaceData({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("setWorkspaceId", null);
    commit("setWorkspace", null);

    commit("setUserWorkspacePermissions", []);

    commit("setWorkspaceSelectedWorkspace", null);
  },

  clearAllPermissionData({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("setWorkspaceId", null);
    commit("setWorkspace", null);

    commit("setUserApps", null);
    commit("setUserWorkspaces", null);
    commit("setUserDocument", null);
    commit("setUserWorkspacePermissions", []);

    commit("setWorkspaceSelectedWorkspace", null);
    commit("setHomeSelectedWorkspace", null);

    commit("setClaim", null);
  },

  setWorkspaceSelectedWorkspace({ commit }, workspaceId) {
    commit("setWorkspaceSelectedWorkspace", workspaceId);
  },

  setHomeSelectedWorkspace({ commit }, workspaceId) {
    commit("setHomeSelectedWorkspace", workspaceId);
  },
};

// saving to localStorage functions

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function findUniqueUserApps(permissions) {
  let apps = [];
  for (const permission of permissions) {
    if (!apps.includes(permission.appId)) {
      apps.push(permission.appId);
    }
  }
  return apps;
}
