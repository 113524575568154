import { ClientDownloadFileMeta } from "@claimsgate/core-types";
/**Downloads a file to the client's browser */
export async function downloadFile(fileMeta: ClientDownloadFileMeta): Promise<void> {
  return new Promise((resolve) => {
    fetch(fileMeta.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = `${fileMeta.fileName}.${fileMeta.fileExtension}`;

        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(url);
        resolve();
      });
  });
}
