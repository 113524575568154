<script lang="ts">
import Vue, { PropType } from "vue";
import { GreyTextColour, TextSize } from "../types";

export default Vue.extend({
  name: "HeadingContent",
  props: {
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: false,
    },
    hideSupportingText: {
      type: Boolean,
      default: false,
    },

    supportingTextClass: {
      required: false,
      type: String as PropType<GreyTextColour>,
      default: "text-grey-700" as GreyTextColour,
    },
    supportingBadge: {
      type: String,
      required: false,
    },
    supportingBadgeClass: {
      type: String,
      default: "text-grey-700",
    },
    headingTextClass: {
      required: false,
      type: String as PropType<GreyTextColour>,
      default: "text-grey-900" as GreyTextColour,
    },
    headingTextSize: {
      required: false,
      type: String as PropType<TextSize>,
      default: "text-lg" as TextSize,
    },
    supportingTextSize: {
      required: false,
      type: String as PropType<TextSize>,
      default: "text-sm" as TextSize,
    },
    headingMarginBottom: {
      required: false,
      type: String,
      default: "mb-1",
    },
  },
});
</script>

<template>
  <div class="d-flex align-items-start justify-content-between w-100">
    <div class="d-inline-block">
      <h4 :class="[headingTextSize, headingMarginBottom]">
        <span :class="[headingTextSize, headingTextClass, 'font-weight-semibold', 'd-inline']">
          {{ heading }}
        </span>
        <p
          v-if="supportingBadge != undefined"
          :class="supportingBadgeClass + ' py-1 px-2 d-inline rounded-pill m-0 text-sm align-middle'"
        >
          {{ supportingBadge }}
        </p>
        <slot name="extraHeadingContent"></slot>
      </h4>
      <p :class="['mb-0', supportingTextClass, supportingTextSize]" v-if="!hideSupportingText">
        {{ supportingText }}
      </p>
      <slot name="extraSupportingContent"></slot>
    </div>
    <div class="float-right">
      <slot name="right"></slot>
    </div>
    <slot></slot>
  </div>
</template>
