/**
 * Adds value to array if the value is not already in the array (may not work with complex types)
 * @param array Array<T>
 * @param value Value that will be appended to Array
 * @returns original array reference
 */
export function appendUnique<T>(array: Array<T>, value: T): Array<T> {
  if (!array.includes(value)) {
    array.push(value);
  }
  return array;
}
