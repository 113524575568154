import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
  ...mapState("auth", {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters({ auth: "auth", loggedIn: "auth/loggedIn", authToken: "auth/getAuthToken" }),
};

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const authMethods = mapActions("auth", [
  "anonLogIn",
  "anonUpgrade",
  "logIn",
  "logOut",
  "register",
  "resetPassword",
  "emailLogin",
  "tryEmailSignIn",
  "setUser",
  "customTokenLogin",
]);

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const eventComputed = {
  ...mapGetters({
    event: "events/getEvent",
    previousEvent: "events/getPreviousEvent",
    eventValues: "events/getEventValues",
  }),
};

export const builderMethods = mapActions("builder", ["addVariable"]);

export const builderComputed = {
  ...mapGetters({
    claimVariables: "builder/getClaimVariables",
    userVariables: "builder/getUserVariables",
    trackingVariables: "builder/getTrackingVariables",
    conditionalDropdown: "builder/getConditionalDropdown",
    actionDropdown: "builder/getActionDropdown",
    funnelVariables: "builder/getVariables",
    apiKey: "builder/getApiKey",
    variablesDropdown: "builder/getVariablesDropdown",
    builderErrors: "builder/getBuilderErrors",
    initalize: "builder/initalize",
    selectedFunnelId: "builder/getFunnelId",
    storeAsMap: "builder/getStoreAsMap",
  }),
};

export const eventMethods = mapActions("event", ["fire"]);

/**
 * Form methods and computed help `Form.vue` perform its operation
 */
export const formMethods = mapActions("form", [
  "initalize",
  "toggleLoader",
  "setFileUploadProgress",
  "resetFileUploadProgress",
]);

export const formComputed = {
  ...mapGetters({
    loaders: "form/getLoaders",
    funnelMetaCache: "form/getFunnelMeta",
    fileUploadProgress: "form/getFileUploadProgressByName",
    filesAreUploading: "form/filesAreUploading",
  }),
};

/**
 * User  methods and computed help `users.vue` perform its operation
 */
export const userMethods = mapActions("users", ["initalize", "toggleLoader"]);

export const userComputed = {
  ...mapGetters({
    loaders: "users/getLoaders",
  }),
};

export const permissionsComputed = {
  ...mapGetters({
    userDocument: "permissions/getUserDocument",
    userWorkspaces: "permissions/getUserWorkspaces",
    workspaceId: "permissions/getWorkspaceId",
    workspace: "permissions/getWorkspace",
    workspaceAllowCustomPolicy: "permissions/getWorkspaceAllowCustomPolicy",
    workspaceSelectedWorkspace: "permissions/getWorkspaceSelectedWorkspace",
    homeSelectedWorkspace: "permissions/getHomeSelectedWorkspace",

    // If we're not a member of this workspace
    // and select this workspace
    // we should not be able to see the members of the workspace as it contains their
    // PID (email, first name and last names)
    workspaceExternalCollaborators: "permissions/getWorkspaceExternalCollaborators",
    workspacePermissions: "permissions/getUserWorkspacePermissions",
  }),
};

export const navigationComputed = {
  ...mapGetters({
    userApps: "permissions/getUserApps",
    navigationWorkspaceId: "navigation/getWorkspaceId",
    navigationWorkspaceName: "navigation/getWorkspaceName",
    navigationLogo: "navigation/getWorkspaceLogo",
    navigationFooter: "navigation/getWorkspaceFooter",
    navigationDisplaySRATag: "navigation/getWorkspaceDisplaySRATag",
    navigationAllowNameChanges: "navigation/getWorkspaceAllowNameChanges",
    navigationAllowAddressChanges: "navigation/getWorkspaceAllowAddressChanges",
    navigationAllowContactDetailChanges: "navigation/getWorkspaceAllowContactDetailChanges",
    navigationAllowClaimDeletion: "navigation/getWorkspaceAllowClaimDeletion",
    navigationAllowAccountDeletion: "navigation/getWorkspaceAllowAccountDeletion",
    navigationHideTrackPageClaimant: "navigation/getWorkspaceHideTrackPageClaimant",
    navigationAllowCustomPolicy: "navigation/getWorkspaceAllowCustomPolicy",

    // navigationLinks: "navigation/getNavigationLinks"
    // onClaimsGateBrandedPage: "navigation/getClaimsGateBrandPageStatus"
  }),
};

export const themesComputed = {
  ...mapGetters({
    theme: "themes/getTheme",
    language: "themes/getLanguage",
  }),
};

export const themeMethods = {
  ...mapActions("themes", ["loadTheme"]),
};

export const metaComputed = {
  ...mapGetters({
    meta: "meta/getMeta",
  }),
};
