import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { BlockProps } from "@/types";

/** Each of the BlockTextProps used in this component */
interface BlockTextProps {
  confirmVehicleText?: Partial<BlockProps.HTML>;
  milbergEligibleVehicleText?: Partial<BlockProps.HTML>;
  milbergIneligibleVehicleText?: Partial<BlockProps.HTML>;
  milbergVrmEntryTitle?: Partial<BlockProps.Text>;
  milbergVrmEntrySubtitle?: Partial<BlockProps.Text>;
  milbergHtml?: Partial<BlockProps.HTML>;
}

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  searchRegButton?: Partial<BlockProps.Button>;
  submitVehicleConfirmationButton?: Partial<BlockProps.Button>;
  submitKeeperSelectButton?: Partial<BlockProps.Button>;
  submitOwnershipDateButton?: Partial<BlockProps.Button>;
  notMyVehicleButton?: Partial<BlockProps.Button>;
}

/** Each of the BlockInputProps used in this component */
interface BlockInputProps {
  ownershipDatePicker: Partial<BlockProps.DatePicker>;
  vehicleConfirmationSingleSelect: Partial<BlockProps.SingleSelect>;
  keeperSelectSingleSelect: Partial<BlockProps.SingleSelect>;
  keeperStartDatePicker: Partial<BlockProps.DatePicker>;
  keeperEndDatePicker: Partial<BlockProps.DatePicker>;
  currentOwnerSingleSelect: Partial<BlockProps.SingleSelect>;
}

export interface MotorSpecsBlockProps extends BlockButtonProps, BlockInputProps, BlockTextProps {}

const texts: BlockTextProps = {
  confirmVehicleText: { html: "<a onclick='test'>Not my vehicle?</a>", height: "auto" },

  milbergVrmEntryTitle: {
    text: "Mercedes and Vauxhall Diesel Emissions Instant Claim Eligibility Checker",
    styles: "h2",
    weight: "medium",
    padding: "0",
    align: "center",
  },
  milbergVrmEntrySubtitle: {
    styles: "h3",
    text: "Find out if you could be owed up to £5,000 by entering your registration number below.",
    padding: "0",
  },
  milbergHtml: {
    html: "<h4>If you owned, leased or financed a Vauxhall or Mercedes Diesel vehicle after January 2017, enter your vehicle registration above to find out if you're eligible to register a claim.</h4>",
    padding: "40",
  },
  milbergEligibleVehicleText: { html: "" },
  milbergIneligibleVehicleText: { html: "" },
};

const buttons: BlockButtonProps = {
  searchRegButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitVehicleConfirmationButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitKeeperSelectButton: { submitButtonDefault: false, text: "Continue", block: false },
  submitOwnershipDateButton: { submitButtonDefault: false, text: "Continue", block: false },
  notMyVehicleButton: { submitButtonDefault: false, text: "Not my vehicle?", block: false, variant: "link" },
};

const inputs: BlockInputProps = {
  ownershipDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: false,
    yearsStart: 2007,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),
  keeperStartDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: false,
    yearsStart: 2007,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),
  keeperEndDatePicker: UserVariableBlocksService.genBlockDatePickerProps({
    displayDays: false,
    yearsStart: 2007,
    yearsEnd: new Date().getFullYear(),
    answer: "" as any,
  }),

  keeperSelectSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({}),
  vehicleConfirmationSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
    options: ["Yes", "No"],
  }),
  currentOwnerSingleSelect: UserVariableBlocksService.genBlockSingleSelectProps({
    options: ["Yes", "No"],
  }),
};

/** Each of the blocks used on the BlockMotorSpecs component */
export const blocks = { texts, buttons, inputs };
